import React from 'react'
import {Link} from 'gatsby'

const MinistryProjects = (props) => {
  const {post, projects, projects2} = props

  const mergedArray = [...projects.edges, ...projects2.edges];


  return (
    <section className="ministry-projects simple-page">
      <div className="container-">
        <div className="row-">
          <div>
            {mergedArray && mergedArray?.length > 0 &&
              <React.Fragment>
              <h3 className="H3-Bold-L---bordeaux m-b-32">Veure projectes i iniciatives d'aquest ministeri:</h3>
              <ul className="p-b-80">
              {mergedArray.map(({node}, index) => {
                return(
                  <li>
                    <Link
                      to={node?.path?.alias}
                      alt={node?.title}
                      >
                      {node?.title}
                    </Link>
                  </li>
                )
              })}
              </ul>
                </React.Fragment>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default MinistryProjects
